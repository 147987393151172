import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Privacy from "../components/Company/Privacy";

const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Privacy")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Firma")}
      />
      <Privacy />
      {/*<RelatedServices />*/}
      <Footer />
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
